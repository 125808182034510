"use client";

import { Fragment, useState } from "react";
import styles from "./faq.module.scss";
import cn from "clsx";
import { pushToDataLayer } from "@/helpers/gtag";
import { getGlobalStateValue } from "@/context/utils";

export default function Faq({
  data,
}: {
  data: { enabled: boolean; Question: string; Answer: string, QuestionCK?: string, AnswerCK ?: string  }[];
}) {
  // Initialize state to keep track of which question is open
  const [openQuestion, setOpenQuestion] = useState<number | null>(null);

  // Function to toggle the open question
  const toggleQuestion = (index: number | null, item:any) => {
    pushToDataLayer({
      event: "faq_section_interaction",
      cta_text: item?.Question || "NA",
      section_name: "frequently asked questions",
      action: openQuestion === index ? "Collapse" : "Expand",
      page_type: getGlobalStateValue('pageType') || "Home Page",
    });
    if (openQuestion === index) {
      // If the clicked question is already open, close it
      setOpenQuestion(null);
    } else {
      // Otherwise, open the clicked question
      setOpenQuestion(index);
    }
  };

  return (
    <Fragment>
      {data.map((item, index) =>
        item.enabled ? (
          <Fragment key={index}>
            <div key={index} className={styles.root}>
              <div
                className="cursor-pointer flex justify-between items-center text-primary-blue"
                onClick={() => toggleQuestion(index, item)}
              >
                {item.QuestionCK ? (
                  <div
                    className={cn(styles.question, {
                      [styles.activeQuestion]: openQuestion === index,
                    })}
                    dangerouslySetInnerHTML={{ __html: item.QuestionCK }}
                  />
                ) : (
                  <h3
                    className={cn(styles.question, {
                      [styles.activeQuestion]: openQuestion === index,
                    })}
                  >
                    {item.Question}
                  </h3>
                )}

                <span className="text-primary-blue text-2xl">
                  {openQuestion === index ? "-" : "+"}
                </span>
              </div>

              {item.AnswerCK ? (
                <div
                  className={cn(styles.answer, {
                    [styles.openAnswer]: openQuestion === index,
                  })}
                  dangerouslySetInnerHTML={{ __html: item.AnswerCK }}
                />
              ) : (
                <p
                  className={cn(styles.answer, {
                    [styles.openAnswer]: openQuestion === index,
                  })}
                >
                  {item.Answer}
                </p>
              )}
            </div>
            <hr className="text-[#D4D4D4] stroke-[1.5px]" />
          </Fragment>
        ) : null
      )}
    </Fragment>
  );
}
