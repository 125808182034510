"use client";

import StudentsCarousel, {
  StudentsVideoCarousel,
} from "@/components/studentsCarousel/StudentsCarousel";
import { StudentsGridCarousel } from "@/components/sections/StudentsCarousel";
import useWindowSize from "@/helpers/useWindowSize";

export default function StudentTestimonialsSection({
  testimonials,
  videoTestimonials,
  sectionHeading=""
}: {
  StudentTestimonials?: any;
  isDesktop?: boolean;
  testimonials: any;
  videoTestimonials: any;
  sectionHeading?:string;
}) {
  const { isDesktop } = useWindowSize();
  if (isDesktop) {
    return (
      <StudentsGridCarousel
        studentsDetails={testimonials}
        videoTestimonials={videoTestimonials}
        sectionHeading={sectionHeading}
      />
    );
  }

  return (
    <div className={"px-3"}>
      <StudentsCarousel studensDetails={testimonials} />
      <StudentsVideoCarousel studentsDetails={videoTestimonials} />
    </div>
  );
}
