'use client'

import styles from "./BuildNation.module.scss";
import cn from "clsx";
import Markdown from "@/components/atoms/Markdown";
import Button from "@/components/atoms/Button";
import strapiSingleAttribute from "@/api/strapiSingleAttribute";
import Link from "next/link";
import Image from "next/image";
import { pushToDataLayer } from "@/helpers/gtag";
import { getGlobalStateValue } from "@/context/utils";

export default function BuildNation({
  item,
  smallFont,
  hasFullWidth,
}: {
  item: any;
  smallFont?: boolean;
  hasFullWidth?: boolean;
}) {
  const img = strapiSingleAttribute(item?.Image?.source || item?.Image);

  const GAEventsHandler = () => {
      pushToDataLayer({
        'event': 'generic_cta_click',
        'cta_text': item?.CTA?.text,
        'section_name': item?.Heading?.text.replace(/_/g, ""),
        'page_type': getGlobalStateValue('pageType')
      });
  }

  return (
    <div className={cn("container", styles.root)}>
      <div className={cn("lg:flex justify-between", styles.wrapper)}>
        <div className={cn(styles.content)}>
          <div className="flex flex-col w-full">
            <div className="flex items-start justify-between w-full">
              {item?.Heading?.enabled && (
                <Markdown
                  as="h2"
                  className={cn(styles.heading, {
                    [styles.smallFont]: smallFont,
                    ["!w-full"]: hasFullWidth,
                  })}
                >
                  {item?.Heading?.text?.replace(/\n/gi, "  \n")} 
                </Markdown>
              )}

              {item?.Icon?.enabled && (
                <img
                  src={item.Icon.source.data?.[0]?.attributes?.url}
                  alt={item.Icon.source.data?.[0]?.attributes?.alternativeText || ''}
                  className="w-[51px] h-[51px] xl:w-[82px] xl:h-[81.95px]"
                  loading="lazy"
                />
              )}
            </div>
            {item?.Description?.enabled && (
              <Markdown as="p"
                className={cn(styles.desc, {
                  ["!w-full"]: hasFullWidth,
                })}
              >
                {item?.Description?.text}
              </Markdown>
            )}
            {item?.CTA?.enabled && (
              <div className={styles.cta}>
                <Button
                  buttonProps={{
                    href: item?.CTA?.url,
                    target: item?.CTA?.target?.[0] || item?.CTA?.target,
                    onClick: GAEventsHandler
                  }}
                  className={styles.ctaBtn}
                  icon="/_a/icons/left-arrow.svg"
                  hoverIcon="/_a/icons/left-arrow-white.svg"
                  bg="o"
                >
                  {item?.CTA?.text}
                </Button>
              </div>
            )}
          </div>
        </div>

        {item?.Image?.enabled && (
          <div className={cn(styles.image_wrapper)}>
            {!!item?.Image?.url ? (
              <Link
                href={item?.Image?.url || "#"}
                scroll={false}
                target={item?.Image?.target?.[0]}
              >
                <Image
                  className={cn("object-cover rounded-2xl", styles.image)}
                  fill
                  src={img?.url}
                  alt={item?.Image?.alt || "image"}
                />
              </Link>
            ) : (
              <Image
                className={cn("object-cover rounded-2xl", styles.image)}
                src={img?.url}
                fill
                alt={item?.Image?.alt || "image"}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
