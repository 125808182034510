"use client";

import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import cn from "clsx";
import styles from "./Faculty.module.scss";
import { useInView } from "react-intersection-observer";
import { useMemo } from "react"
import strapiSingleAttribute from "@/api/strapiSingleAttribute";
import Image from "next/image";
import { IModalActions, ModalTypes } from "@/types/modal";
import { ModalButton } from "@/components/atoms/ModalButton";

export default function FacultyCarousel({
  facultyDetails,
  isDesktop,
}: {
  facultyDetails: Faculty[];
  isDesktop: boolean;
}) {
  const { ref, inView } = useInView({
    rootMargin: "200px 0px 0px 0px",
  });

  if (isDesktop) {
    return (
      <div className={cn(styles.root)} ref={ref}>
        {inView ? (
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={0}
            slidesPerView={1}
            autoplay={{
              delay: 6000,
            }}
            pagination={{ clickable: true }}
            className="Carousel__blueBullet Carousel__hAuto"
          >
            {renderFacultySlideForLaptop(facultyDetails)}
          </Swiper>
        ) : null}
      </div>
    );
  }
  return (
    <div className={cn(styles.root)} ref={ref}>
      {inView ? (
        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{
            delay: 6000,
          }}
          pagination={{ clickable: true }}
          className="Carousel__blueBullet Carousel__hAuto"
          breakpoints={{
            1199: {
              slidesPerView: 1,
            },
            1200: {
              slidesPerView: 3,
            },
          }}
        >
          {facultyDetails.map(({ id, attributes }, i) => (
            <SwiperSlide key={id}>
              <FacultySlide id={id} attributes={attributes} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : null}
    </div>
  );
}

function renderFacultySlideForLaptop(faculties: Faculty[]) {
  const res = [];
  for (let i = 0; i < faculties.length; i += 3) {
    const faculty1 = faculties[i];
    const faculty2 = faculties[i + 1];
    const faculty3 = faculties[i + 2];
    res.push(
      <SwiperSlide key={i}>
        <div className="flex gap-12 w-[100%]">
          <FacultySlide id={faculty1.id} attributes={faculty1.attributes} />
          {i + 1 < faculties.length ? (
            <FacultySlide id={faculty2.id} attributes={faculty2.attributes} />
          ) : null}
          {i + 2 < faculties.length ? (
            <FacultySlide id={faculty3.id} attributes={faculty3.attributes} />
          ) : null}
        </div>
      </SwiperSlide>,
    );
  }

  return res;
}

function FacultySlide({
  id,
  attributes: {
    Department,
    Designation,
    Name,
    Picture,
    ActionIcon,
    BorderColor,
    Video,
  },
}: Faculty) {

  const img = useMemo(() => {
    return strapiSingleAttribute(Picture?.source || Picture);
  }, [Picture]);

  return (
    <>
      <div
        className={cn(styles.cardContainer)}
        style={{ background: BorderColor }}
      >
        <div
          className={cn(
            "border-primary-blue overflow-hidden bg-white",
            styles.imageBox
          )}
        >
          <img
            src={img?.url}
            alt={Name || ""}
            className={styles.image}
          />
        </div>
        <div
          className={cn(styles.textContainer)}
          style={{ background: BorderColor }}
        >
          <div
            className={cn(
              "bg-white text-primary-blue flex items-center justify-between gap-3",
              styles.details
            )}
          >
            <div>
              <h3 className={cn(styles.name)}>{Name}</h3>
              <p className={cn(styles.desg, "xl:hidden block")}>
                {Department}, {Designation}
              </p>
              <p className={cn(styles.desg, "hidden xl:block")}>
                {Department}, <br />
                {Designation}
              </p>
            </div>

            {ActionIcon?.length > 0 &&
              [ActionIcon[0]].map((_d, i: number) => (
                <ModalButton
                  key={i}
                  action={IModalActions.OpenModal}
                  data={{
                    modalType: ModalTypes.VideoPopup,
                    modalData: { url: Video?.URL },
                  }}
                  className={`cursor-pointer lg:block homeSprite ${styles.playIcon}`}
                  ariaLableText="play"
                />
              ))}
          </div>
        </div>
      </div>
    </>
  )
}
