"use client";

import clickToScroll from "@/api/dragScroll";
import { useEffect } from "react";

export default function ClickToScroll({ id }) {
  useEffect(() => {
    clickToScroll(id);
  }, [id]);
  return null;
}
